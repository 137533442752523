import React, { useRef } from "react";
import { NavBarHeight } from "../utils/constant";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { TextHighLight } from "./CustomUI";

const Experience = () => {
    const container = useRef(null);
    const topHeight = NavBarHeight;

    useGSAP(
        () => {
            const boxes = document.getElementById('progress-bar');
            const experienceMain = document.getElementById('Experience-main');

            gsap.from(boxes, {
                scaleY: 0,
                transformOrigin: "top",
                // duration: 1,
                ease: "linear",
                scrollTrigger: {
                    trigger: experienceMain,
                    start: "top 70%",
                    end: "bottom 50%",
                    scrub: 1,
                    // markers: true,
                },
            });

            const experienceDesc = gsap.utils.toArray('.experience-desc');
            experienceDesc.forEach((experienceDescEle, index) => {
                gsap.from(experienceDescEle, {
                    opacity: 0,
                    ease: 'bounce',
                    scrollTrigger: {
                        trigger: experienceDescEle,
                        start: "top 50%",
                        end: "top 40%",
                        scrub: 1,
                        // markers: true,
                    }
                })
            })

        },
        { scope: container }
    )

    return (
        <>
            <div id="Experience"  ref={container} className="w-[80vw] mx-[10vw] content-center py-[50px] experience-section">
                <p className="text-4xl font-semibold">Experience</p>
                <p className="text-2xl font-semibold text-gray-500 mt-5">My skills are like a full-stack toolkit — ranging from crafting dynamic UIs with React.js to building robust backend systems with Express.js and Node.js. Add in DSA wizardry, C++ precision, JavaScript mastery, and a knack for logical thinking, and you've got a developer ready to tackle any challenge with style!</p>
                <div className="experience-show mt-5">
                    <div id="Experience-main" className="scroll-progress-divs relative h-[100%]">
                        <div className="absolute top-[0px] w-[5px] h-[100%] bg-gray-500/10 rounded-full "></div>
                        <div id="progress-bar" className="absolute top-[0px] w-[5px] h-[100%] bg-green-500 rounded-full "></div>
                        
                        <div className=" w-[100%] flex">
                            {/* <div className="w-[10px] h-[10px]">Tick Mark</div> */}

                            <div className="experience-desc w-[100%] ml-[20px] my-[20px] shadow-xl p-[20px] rounded-xl">
                                <div className="flex justify-between mb-2">
                                    <p className=" text-2xl font-semibold ">ReactJs Developer</p>
                                    <p className=" text-xl italic ">JAN 2024 - PRESENT</p>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <p className=" text-2xl font-semibold">ReactJs Developer Intern</p>
                                    <p className=" text-xl italic ">JULY 2023 - JAN 2024</p>
                                </div>
                                <div className="flex justify-around mb-3">
                                    <p className=" text-3xl font-semibold"><span className="text-xl font-light">at</span> IdeaHelix</p>
                                </div>
                                <ul className="list-disc ml-[20px]">
                                    <li className=" text-2xl my-2">Built a<TextHighLight hiLghtNo={2} curNo={3} bgClr={"bg-sky-500/50"} > secret handshake </TextHighLight>between two React.js websites using 
                                        <span className="font-semibold italic" > cross-domain communication (parent-child iframe magic)</span> with the 
                                        <span className="font-semibold italic"> postMessage</span> event —<TextHighLight hiLghtNo={3} curNo={4} bgClr={"bg-[#A086EA]/50"} > because even websites need to talk behind the scenes! 
                                        </TextHighLight>
                                    </li>
                                    <li className=" text-2xl my-2">Made <TextHighLight hiLghtNo={4} curNo={5} bgClr={"bg-[#66F63A]/50 "} >
                                        <span className="font-semibol">APIs hit the gym by merging redundant calls and flexing Redux + Redux-Thunk</span></TextHighLight> — <span className="font-semibold italic">cutting network requests by 40%</span> and making responses <span className="font-semibold italic">lightning-fast</span> 🚀
                                    </li>
                                    <li className=" text-2xl my-2">
                                        <TextHighLight hiLghtNo={5} curNo={6} bgClr={"bg-[#42F9BB]/50 "} > Slashed page load times like a ninja</TextHighLight> with <TextHighLight hiLghtNo={6} curNo={7} bgClr={"bg-[#A45139]/50"} >code-splitting, lazy loading, and dynamic imports </TextHighLight> — <span className="font-semibold italic">boosting performance by 55%</span> and<TextHighLight hiLghtNo={7} curNo={8} bgClr={"bg-[#F1415F]/50"} > making users wonder if their internet just got an upgrade </TextHighLight>
                                    </li>
                                    <li className=" text-2xl my-2">
                                        <TextHighLight hiLghtNo={8} curNo={9} bgClr={"bg-[#44A275]/50"} >Led a squad of 4 devs, </TextHighLight>mastering the art of<TextHighLight hiLghtNo={9} curNo={10} bgClr={"bg-[#FE4210]/50"} > task juggling, workflow wizardry, and stakeholder charm, </TextHighLight><span className="font-semibold italic">ensuring smooth deliveries and maximum productivity</span> <TextHighLight hiLghtNo={10} curNo={11} bgClr={"bg-[#FD0C45]/25 "} >(with minimum panic) 😎</TextHighLight>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=" w-[100%] flex">
                            {/* <div className="w-[10px] h-[10px]">Tick Mark</div> */}

                            <div className="experience-desc w-[100%] ml-[20px] my-[20px] shadow-xl p-[20px] rounded-xl">
                                <div className="flex justify-between mb-2">
                                    <p className=" text-2xl font-semibold ">Web Developer Intern</p>
                                    <p className=" text-xl italic ">AUG 2021 - SEP 2021</p>
                                </div>
                                <div className="flex justify-around mb-3">
                                    <p className=" text-3xl font-semibold"><span className="text-xl font-light">at</span> Lets Grow More</p>
                                </div>
                                <ul className="list-disc ml-[20px]">
                                    <li className=" text-2xl my-2">
                                        <TextHighLight hiLghtNo={1} curNo={2} bgClr={"bg-[#66F63A]/50 "} >Crafted a sleek, multi-featured, and fully responsive website</TextHighLight> using <span className="font-semibold italic">HTML, JS, and CSS</span> —<TextHighLight hiLghtNo={2} curNo={3} bgClr={"bg-[#102846]/15 "} > because good design deserves great code! ✨</TextHighLight>
                                    </li>
                                    <li className=" text-2xl my-2">
                                        Brought<TextHighLight hiLghtNo={3} curNo={4} bgClr={"bg-[#9D03C0]/50 "} > profiles to life with a React.js web app </TextHighLight>that fetches and displays user data like a pro — <TextHighLight hiLghtNo={4} curNo={5} bgClr={"bg-[#EF9D47]/50 "} >turning APIs into storytelling machines. 🚀</TextHighLight>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                        
                    
                </div>
            </div>
        </>
    )
}

export default Experience;