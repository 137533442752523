import { demo } from "./constant";

const backendUrl = demo ? 'http://localhost:8080' : 'https://backend.gvishnu.in';

export const services = {
    postContactMe: async (data) => {
        const res = await fetch(`${backendUrl}/contactMe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
            return "err";
        })

        return res;
    },
}