import React from "react";
import { NavBarHeight } from "../utils/constant";
import HeroSection from "../components/HeroSection";
import ProjectSection from "../components/ProjectSection";
import Skills from "../components/Skills";
import Experience from "../components/Experience";
import ContactMe from "../components/ContactMe";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
        <div className="home" style={{paddingTop: NavBarHeight}}>
            <HeroSection />
            <Skills />
            <ProjectSection />
            <Experience />
            <ContactMe />
            <Footer />
        </div>
        </>
    )
}

export default Home;