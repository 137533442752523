import { useState } from "react";
import { services } from "../utils/service";

const ContactMe = () => {
    const [finalRes, setFinalRes] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const setResponse = (res) => {
        setFinalRes(res);
        setIsLoading(false);
        setTimeout(() => {
            setFinalRes('');
        }, 5000); // Clear the message after 5 seconds
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isLoading != false) return;
        setIsLoading(true);

        const formData = new FormData(e.target);
        const name = formData.get("name");
        const email = formData.get("email");
        const message = formData.get("message");

        if (name === "" || email === "" || message === "") {
            setResponse("Please fill in all fields");
            return;
        }

        const res = await services.postContactMe({
            "receiverMail": "ganeshanvishnu@gmail.com",
            "content": {
                "name": name,
                "email": email,
                "message": message
            }
        });

        // console.log(res, "dhdsaidjlka");
        if(res === "err") {
            setResponse("There was an error sending your message. Please try again later.");
            return;
        }

        setResponse(`Thank you ${name} for your message! I will get back to you soon.`);
        e.target.reset(); // Reset the form fields
    }

    return (
        <>
        <div id="ContactMe" className="w-[80vw] mx-[10vw] content-center py-[50px] scroll-mt-[70px]">
            <p className="text-4xl font-semibold">Contact Me</p>
            <p className="text-2xl font-semibold text-gray-500 mt-5">
            "Let’s make the internet a little more interesting — one message at a time! Whether it’s about work, tech talk, or just exchanging memes, I’m all ears. Drop a message, and let’s connect!" 😎📩
            </p>
            {finalRes && (
                <div className={`mt-5 py-4 text-2xl ${finalRes.includes('Thank you') ? 'text-green-500' : 'text-red-500'}`}>
                    {finalRes}
                </div>
            )}
            {isLoading && (
                <div className={`mt-5 py-4 text-2xl text-gray-500`}>
                    Sending your message...
                </div>
            )}
            <form className="contactDiv w-[100%]" onSubmit={handleSubmit}>
                <div className="flex w-[100%] justify-between mt-5">
                    <input className="border-2 border-gray-500 p-[5px] rounded-xl text-2xl w-[100%] mr-5 capitalize" type="text" name="name" placeholder="Your Awesome Name" />
                    <input className="border-2 border-gray-500 p-[5px] rounded-xl text-2xl w-[100%] ml-5" type="email" name="email" placeholder="Your Magic Email Address" />
                </div>
                <textarea className="border-2 border-gray-500 p-[5px] rounded-xl text-2xl w-[100%] my-5" name="message" rows={5} placeholder="Spill the details... What’s on your mind?" />
                <button className="bg-gray-800 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 cursor-pointer hover:bg-black text-2xl text-semibold text-gray-100 shadow-lg py-3 px-9 rounded-xl" type="submit" >Launch Message 🚀</button>
            </form>
        </div>
        </>
    )
}

export default ContactMe;