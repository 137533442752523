import React, { useEffect, useState } from "react";

const sketchBorders = [
    '97% 29% 100% 0% / 36% 17% 30% 0%',
    '0% 100% 0% 100% / 0% 21% 10% 28%',
    '97% 29% 100% 0% / 14% 17% 12% 0%',
    '68% 88% 85% 85% / 13% 9% 0% 17%',
    '68% 84% 45% 50% / 36% 20% 28% 17%',
    '95% 81% 5% 19% / 16% 20% 28% 16%',
    '100% 100% 100% 100% / 45% 0% 56% 6%',
]

export const TextHighLight = ({ hiLghtNo, curNo, bgClr, children }) => {
    const customClassName = "transition duration-[500ms] linear " + (curNo > hiLghtNo  ? ` ${bgClr} ` : ' ');

    return <span className={customClassName} style={{borderRadius: sketchBorders[(hiLghtNo % sketchBorders?.length)]}}>{children}</span>
}

export const TextTyping = ({ children, delayMs }) => {
    const [resultText, setResultText] = useState('');

    useEffect(() => {
        const timerRef = setInterval(() => {
            setResultText(prevText => {
                const curLenght = prevText?.length;
                const finalLenght = children?.length;
                console.log("intervalCallBack", curLenght, finalLenght);
    
                if(finalLenght <= curLenght + 1) clearInterval(timerRef);
                return prevText + children[curLenght];
            })
        }, [delayMs ?? 100])

        return () => clearInterval(timerRef)
    }, [])

    return <>{resultText}{(children != resultText) ? <span className="">...</span> : ''}</>;
}